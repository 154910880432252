<section class="section-pd list-wrapper-style-2 onlyEnterprise">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Check Grammar Without Internet</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka’s Windows Desktop App for enterprises provides grammar correction <b>without internet</b>, ensuring <b>absolute data privacy</b>, but with the power of AI.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <div class="features_cards_icon">
                        <svg width="40" height="40" viewBox="0 0 24 24" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#763393"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{display:none;} .st1{display:inline;} .st2{opacity:0.2;fill:none;stroke:#763393;stroke-width:5.000000e-02;stroke-miterlimit:10;} </style> <g class="st0" id="grid_system"></g> <g id="_icons"> <path d="M2,14.5c0,1.6,0.9,3.1,2.2,3.9l-0.9,0.9c-0.4,0.4-0.4,1,0,1.4C3.5,20.9,3.7,21,4,21s0.5-0.1,0.7-0.3l14-14 c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-1.8,1.8C14.9,7,14.3,7,13.8,7c-0.1-0.2-0.3-0.3-0.4-0.4C12.4,5.6,11,5,9.5,5S6.6,5.6,5.6,6.6 C4.6,7.6,4,9,4,10.5c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.5,0.3-0.7,0.6C2.5,12.2,2,13.3,2,14.5z M4.7,12.7c0.2-0.2,0.5-0.4,0.7-0.5 c0.4-0.2,0.7-0.6,0.6-1.1C6,10.9,6,10.7,6,10.5C6,9.6,6.4,8.7,7,8c1.3-1.3,3.6-1.3,4.9,0c0.2,0.2,0.4,0.4,0.5,0.7 c0.2,0.3,0.6,0.5,1,0.4l-7.7,7.7c-1-0.3-1.7-1.3-1.7-2.4C4,13.8,4.3,13.2,4.7,12.7z"></path> <path d="M19.6,10.6c-0.2-0.4-0.4-0.8-0.6-1.2c-0.3-0.5-0.9-0.6-1.4-0.3c-0.5,0.3-0.6,0.9-0.3,1.4c0.2,0.3,0.4,0.7,0.5,1 c0.1,0.3,0.3,0.5,0.6,0.7c0.9,0.4,1.5,1.3,1.5,2.3c0,0.7-0.3,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.8,0.7H10c-0.6,0-1,0.4-1,1s0.4,1,1,1 h7.5c1.2,0,2.3-0.5,3.2-1.3c0.9-0.8,1.3-2,1.3-3.2C22,12.8,21.1,11.3,19.6,10.6z"></path> </g> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Offline Processing </h3>
                        <p>Installs and runs locally on your Laptop/PC without requiring any internet connection.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <div class="features_cards_icon">
                        <svg width="40px" height="40px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" fill="#763393"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" stroke="#763393" stroke-width="12"> <path stroke-linecap="round" d="M151.8 144.5a74 74 0 0 1-85.59 19.21A74 74 0 0 1 22.42 87.7a74 74 0 0 1 59.55-64.42m28.03.06a74 74 0 0 1 50.06 35.61 74 74 0 0 1 5.915 61.15"></path> <path d="M76 92h40c4.432 0 8 3.568 8 8v22c0 4.432-3.568 8-8 8H76c-4.432 0-8-3.568-8-8v-22c0-4.432 3.568-8 8-8zm4 0V77.7C80 69.029 87.163 62 96 62s16 7.029 16 15.7V92"></path> </g> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Complete Data Privacy</h3>
                        <p>As it works on your Laptop/PC, your data is never shared or uploaded on any server, ensuring full privacy.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <div class="features_cards_icon">
                        <svg width="40" height="40" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5762 13.3506C19.5762 15.7938 21.5568 17.7745 24 17.7745C26.4432 17.7745 28.4239 15.7938 28.4239 13.3506C28.4239 10.9074 26.4432 8.92676 24 8.92676C21.5568 8.92676 19.5762 10.9074 19.5762 13.3506ZM35.8012 8.92676C33.358 8.92676 31.3773 10.9074 31.3773 13.3506C31.3773 15.7938 33.358 17.7745 35.8012 17.7745C38.2444 17.7745 40.225 15.7938 40.225 13.3506C40.225 10.9074 38.2444 8.92676 35.8012 8.92676ZM7.77502 13.3506C7.77502 15.7938 9.75565 17.7745 12.1989 17.7745C14.6421 17.7745 16.6227 15.7938 16.6227 13.3506C16.6227 10.9074 14.6421 8.92676 12.1989 8.92676C9.75565 8.92676 7.77502 10.9074 7.77502 13.3506ZM19.5762 25.1518C19.5762 27.595 21.5568 29.5756 24 29.5756C26.4432 29.5756 28.4239 27.595 28.4239 25.1518C28.4239 22.7085 26.4432 20.7279 24 20.7279C21.5568 20.7279 19.5762 22.7085 19.5762 25.1518ZM31.3773 25.1518C31.3773 27.595 33.358 29.5756 35.8012 29.5756C38.2444 29.5756 40.225 27.595 40.225 25.1518C40.225 22.7085 38.2444 20.7279 35.8012 20.7279C33.358 20.7279 31.3773 22.7085 31.3773 25.1518ZM7.77502 25.1518C7.77502 27.595 9.75565 29.5756 12.1989 29.5756C14.6421 29.5756 16.6227 27.595 16.6227 25.1518C16.6227 22.7085 14.6421 20.7279 12.1989 20.7279C9.75565 20.7279 7.77502 22.7085 7.77502 25.1518ZM19.5762 36.9529C19.5762 39.3961 21.5568 41.3768 24 41.3768C26.4432 41.3768 28.4239 39.3961 28.4239 36.9529C28.4239 34.5097 26.4432 32.5291 24 32.5291C21.5568 32.5291 19.5762 34.5097 19.5762 36.9529ZM31.3773 36.9529C31.3773 39.3961 33.358 41.3768 35.8012 41.3768C38.2444 41.3768 40.225 39.3961 40.225 36.9529C40.225 34.5097 38.2444 32.5291 35.8012 32.5291C33.358 32.5291 31.3773 34.5097 31.3773 36.9529ZM7.77502 36.9529C7.77502 39.3961 9.75565 41.3768 12.1989 41.3768C14.6421 41.3768 16.6227 39.3961 16.6227 36.9529C16.6227 34.5097 14.6421 32.5291 12.1989 32.5291C9.75565 32.5291 7.77502 34.5097 7.77502 36.9529Z" fill="#763393"/>
                        </svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Data Sovereignty</h3>
                        <p>This solution does not transfer data out of your Laptop/PC, thus meeting all data sovereignty requirements with ease.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <div class="features_cards_icon">
                        <svg width="40" height="40" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M60 28.8C60 16.8 55.2 12 43.2 12H28.8C16.8 12 12 16.8 12 28.8V43.2C12 55.2 16.8 60 28.8 60" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M49.05 24C47.4 21.9 44.64 21 40.5 21H31.5C24 21 21 24 21 31.5V40.5C21 44.64 21.9 47.4 23.97 49.05" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.03 12V6" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M36 12V6" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M48 12V6" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M60 24H66" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.03 60V66" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 24H12" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 36H12" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 48H12" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M66 40L40 66" stroke="#7A28A0" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M66 66L40 40" stroke="#7A28A0" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>No AI <br class="d-lg-block d-none"/>Training</h3>
                        <p>As your data is never shared or stored on any server, it cannot be used for training AI models.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <div class="features_cards_icon">
                        <svg width="40" height="40" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.47 6.68999L16.5 12.33C13.05 13.62 10.23 17.7 10.23 21.36V43.65C10.23 47.19 12.57 51.84 15.42 53.97L28.32 63.6C32.55 66.78 39.51 66.78 43.74 63.6L56.64 53.97C59.49 51.84 61.83 47.19 61.83 43.65V21.36C61.83 17.67 59.01 13.59 55.56 12.3L40.59 6.68999C38.04 5.75999 33.96 5.75999 31.47 6.68999Z" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.993 2.67432L9.19709 8.127C5.78721 9.37415 3 13.3186 3 16.8571V38.4068" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.15 35.61L31.98 40.44L44.88 27.54" stroke="#7A28A0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>Faster & Powerful </h3>
                        <p>It is fast & powerful, while delivering similar level of advanced grammar and technical spelling corrections that Trinka is known for.</p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1">
                    <div class="features_cards_icon">
                        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M22 19H14M2 19H10" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> <path d="M12 17V14" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="12" cy="19" r="2" stroke="#763393" stroke-width="1.5"></circle> <path d="M2 11C2 9.34315 3.34315 8 5 8H19C20.6569 8 22 9.34315 22 11C22 12.6569 20.6569 14 19 14H5C3.34315 14 2 12.6569 2 11Z" stroke="#763393" stroke-width="1.5"></path> <path d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5C22 6.65685 20.6569 8 19 8H5C3.34315 8 2 6.65685 2 5Z" stroke="#763393" stroke-width="1.5"></path> <path d="M13 5L19 5" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> <path d="M13 11L19 11" stroke="#763393" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="6" cy="5" r="1" fill="#763393"></circle> <circle cx="6" cy="11" r="1" fill="#763393"></circle> </g></svg>
                    </div>
                    <div class="features_cards_desc">
                        <h3>No Servers Needed</h3>
                        <p>This solution does not require any server or IT setup at your end. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="complianceBox">
                    <div class="trinka-title text-center">
                        <h3>Your Data Remains on Your Laptop/PC Only.<br/>Nobody Can See It or Access It</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn" type="button">Contact Us</button>
                        <button class="filled-btn sensitive-btn" type="button" (click)="triggerScroll()">Contact Us</button>
                        <a [routerLink]="['/windows-desktop-app']" class="outline-btn">Know more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>