<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <!-- <div class="cloud_top_banners">
        <div class="container">
            <div class="row">
                <div class="col-lg-">
                    <div class="trinka_cloud_banner_text">
                        <h1>Key Features of Trinka</h1>
                        <p>Trinka is the world’s first grammar and language enhancement tool custom-built for academic and technical
                            writing. Trinka stands out by working with the author and going beyond grammar and spelling checks
                            to ensure holistic language enhancement.</p>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/FeaturesTopBanner.png" alt="banner">
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>All Features</h1>
                            <h2>Key Features of Trinka</h2>
                            <p>Trinka is the world’s first grammar and language enhancement tool custom-built for academic and technical writing. Trinka stands out by working with the author and going beyond grammar and spelling checks to ensure holistic language enhancement.</p>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../assets/images/FeaturesTopBanner.png" alt="banner" class="md50">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="advanceGrammerSpellCheck_section backgrondSecitons_outer mt-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-50 text-center">
                        <h2>Powerful Grammar and <br class="d-md-block d-none"/>Spelling Checks</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Academic writing needs to be clear, concise, formal, objective, and readable. Trinka's holistic improvements let you communicate confidently.</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="spellcheckGrammerCheck_slider">
                        <div id="spellcheckGrammerCheck" class="carousel slide carousel-arrows" data-ride="carousel">
                            <div class="bullet_points_sections">
                                <ul class="carousel-indicators1 carousel-indicators">
                                    <li data-target="#spellcheckGrammerCheck" data-slide-to="0" class="active">
                                        <span></span>
                                        <i>Advanced Grammar</i>
                                    </li>
                                    <li data-target="#spellcheckGrammerCheck" data-slide-to="1">
                                        <span></span>
                                        <i>Sentence Structure</i>
                                    </li>
                                    <li data-target="#spellcheckGrammerCheck" data-slide-to="2">
                                        <span></span>
                                        <i>Word Choice</i>
                                    </li>
                                    <li data-target="#spellcheckGrammerCheck" data-slide-to="3">
                                        <span></span>
                                        <i>Usage and Style</i>
                                    </li>
                                    <li data-target="#spellcheckGrammerCheck" data-slide-to="4">
                                        <span></span>
                                        <i>US/UK Style</i>
                                    </li>
                                    <li data-target="#spellcheckGrammerCheck" data-slide-to="5">
                                        <span></span>
                                        <i>Advanced Spelling</i>
                                    </li>
                                </ul>
                            </div>

                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <h4>Advanced grammar</h4>
                                    <p>Trinka corrects 3000+ complex grammar errors that other tools don’t. With Trinka, make
                                        your writing clean and clear.</p>
                                    <div class="featuresExampleText">
                                        <p>Neither the healthy individuals nor the patient
                                            <span class="wrongText">recognize</span>
                                            <span class="rightText">recognizes</span> the visual discrepancies.</p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Sentence structure</h4>
                                    <p>Trinka structures your sentences correctly considering your subject, making your writing
                                        easy to understand.</p>
                                    <div class="featuresExampleText">
                                        <p>The students don't enjoy
                                            <span class="wrongText">to go</span>
                                            <span class="rightText">going</span> over the same rules again and again.</p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Word choice</h4>
                                    <p>Trinka fixes incorrect use of words that makes your writing unclear. Convey your intent
                                        accurately and clearly with Trinka.</p>
                                    <div class="featuresExampleText">
                                        <p>One of the reasons is
                                            <span class="wrongText">because</span>
                                            <span class="rightText">that</span> the fabrication cost
                                            <span class="wrongText">for</span>
                                            <span class="rightText">of</span> steel members depends upon the material.</p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Usage and style</h4>
                                    <p>Trinka corrects phrasing and gives your writing a formal tone. Impress your readers with
                                        bold, precise language.</p>
                                    <div class="featuresExampleText">
                                        <p>However, his method is novel and is
                                            <span class="wrongText">very much</span>
                                            <span class="rightText">considerably</span> different from the classical method.
                                        </p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>US/UK style</h4>
                                    <p>Choose between US or UK English and let Trinka help you make your writing fit for your
                                        audience and journal style.</p>
                                    <div class="featuresExampleText pl-40 pr-40">
                                        <p>The upper limbs of the patient were
                                            <span class="wrongText">paralysed</span>
                                            <span class="rightText">paralyzed.</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Advanced spelling</h4>
                                    <p>Trinka’s contextual spelling check helps you pick the right word for your subject. No
                                        more awkward spelling errors.</p>
                                    <div class="featuresExampleText">
                                        <p>The
                                            <span class="wrongText">Bonferoni</span>
                                            <span class="rightText">Bonferroni</span> test was used to check for statistical significance.</p>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#spellcheckGrammerCheck" data-slide="prev">
                                    <span>
                                        <svg enable-background="new 0 0 240.823 240.823" version="1.1" viewBox="0 0 240.82 240.82" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m57.633 129.01 108.3 108.26c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0l-108.3 108.26c-4.679 4.691-4.679 12.511 0.012 17.191z"/>
                                        </svg>
                                    </span>
                                </a>
                                <a class="carousel-control-next" href="#spellcheckGrammerCheck" data-slide="next">
                                    <span>
                                        <svg enable-background="new 0 0 240.823 240.823" version="1.1" viewBox="0 0 240.82 240.82" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m57.633 129.01 108.3 108.26c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0l-108.3 108.26c-4.679 4.691-4.679 12.511 0.012 17.191z"/>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="advanceGrammerSpellCheck_section publishEnhancements_slider section-pd pb-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title text-center">
                        <h2>Enhancements for Publication<br class="d-md-block d-none"/>-Ready Writing</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka refines the tone, delivery, and phrasing of your writing to match academic writing conventions
                            and best practices so you can focus more on your ideas.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="spellcheckGrammerCheck_slider">
                        <div id="publishEnhancements" class="carousel slide carousel-arrows" data-ride="carousel">
                            <div class="bullet_points_sections">
                                <ul class="carousel-indicators1 carousel-indicators">
                                    <li data-target="#publishEnhancements" data-slide-to="0" class="active">
                                        <span></span>
                                        Style Guide Preferences
                                    </li>
                                    <li data-target="#publishEnhancements" data-slide-to="1">
                                        <span></span>
                                        Word Count Reduction
                                    </li>
                                    <li data-target="#publishEnhancements" data-slide-to="2">
                                        <span></span>
                                        Unbiased Language
                                    </li>
                                    <li data-target="#publishEnhancements" data-slide-to="3">
                                        <span></span>
                                        Vague Language
                                    </li>
                                    <li data-target="#publishEnhancements" data-slide-to="4">
                                        <span></span>
                                        Technical Phrasing
                                    </li>
                                    <li data-target="#publishEnhancements" data-slide-to="5">
                                        <span></span>
                                        Academic Tone
                                    </li>
                                </ul>
                            </div>

                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <h4>Style guide preferences</h4>
                                    <p>Trinka suggests phrasing preferred by style guides such as AMA and APA. Get your writing
                                        ready for publication with Trinka.</p>
                                    <div class="featuresExampleText">
                                        <p>The man
                                            <span class="wrongText">suffered from</span>
                                            <span class="rightText">had</span> chronic kidney disease.</p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Word count reduction</h4>
                                    <p>Trinka makes redundant and wordy phrases concise for you. Now meet journal guidelines
                                        with ease!</p>
                                    <div class="featuresExampleText">
                                        <p>Bcl6 deficiency in T cells resulted in
                                            <span class="wrongText">the reduction of
                                            </span>
                                            <span class="rightText">reduced</span> mRNA expression.</p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Unbiased language</h4>
                                    <p>Trinka corrects biased and insensitive language. Avoid criticism and make your point
                                        effectively with Trinka.</p>
                                    <div class="featuresExampleText">
                                        <p>The
                                            <span class="wrongText">retarded children</span>
                                            <span class="rightText">children with mental retardation</span> were taken to a different facility.</p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Vague language</h4>
                                    <p>Trinka helps you make your writing specific and direct. Effective communication is easy
                                        with Trinka.</p>
                                    <div class="featuresExampleText">
                                        <p>
                                            <span class="wrongText">Some studies</span>
                                            <span class="rightText">Studies</span> have shown that children not participating in co-curricular activities
                                            may not develop the necessary social skills in adulthood.
                                        </p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Technical phrasing</h4>
                                    <p>Trinka suggests the right words to use for your subject area. Make your content relatable
                                        to your readers with Trinka.</p>
                                    <div class="featuresExampleText">
                                        <p>Anti-aquaporin 4 antibody testing may be useful in cases of pediatric
                                            <span class="wrongText">transverse myelitis with long spinal cord lesions
                                            </span>
                                            <span class="rightText">longitudinally extensive transverse myelitis.</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <h4>Academic tone</h4>
                                    <p>Trinka gives your writing a formal tone so you can impress your readers. Professional
                                        writing is now easy!</p>
                                    <div class="featuresExampleText">
                                        <p>Children actively engage in activities
                                            <span class="wrongText">like</span>
                                            <span class="rightText">such as</span> outdoor games, skits, and other team activities.
                                        </p>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#publishEnhancements" data-slide="prev">
                                    <span>
                                        <svg enable-background="new 0 0 240.823 240.823" version="1.1" viewBox="0 0 240.82 240.82" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m57.633 129.01 108.3 108.26c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0l-108.3 108.26c-4.679 4.691-4.679 12.511 0.012 17.191z"/>
                                        </svg>
                                    </span>
                                </a>
                                <a class="carousel-control-next" href="#publishEnhancements" data-slide="next">
                                    <span>
                                        <svg enable-background="new 0 0 240.823 240.823" version="1.1" viewBox="0 0 240.82 240.82" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m57.633 129.01 108.3 108.26c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0l-108.3 108.26c-4.679 4.691-4.679 12.511 0.012 17.191z"/>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="writeToolboxs_section section-pd pb-0">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Writer's Suite</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
        </div>

        <section class="section-pd list-wrapper-style-2">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3 class="d-flex align-items-center">AI Studio <div class="beta-badge">NEW</div></h3>
                            <p>Create high-quality content quickly, at scale, with the help of specialized custom-built AI Apps.</p>
                            <a [routerLink]="['/features/ai-studio/ai-apps-library']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img pr-0">
                            <img src="../../../assets/images/features/ai-studio/ai-app-library-banner.png" loading="lazy" decoding="async" alt="proofread">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Plagiarism Check</h3>
                            <p>
                                Experience the highest quality of plagiarism check with the most advanced text similarity detection algorithm and the  largest paid publication database covering all scientific fields.
                            </p>
                            <a [routerLink]="['/features/plagiarism-check']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image">
                            <img src="../../../assets/images/features/pc-check.jpg" loading="lazy" decoding="async" alt="pc checker">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3>Proofread File</h3>
                            <p>Apply powerful language revisions in one go—all tracked for your review. Simply upload your MS
                                Word document, let Trinka auto-edit your paper in minutes, and download your document with
                                all changes tracked.</p>
                            <a [routerLink]="['/features/proofread-file']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/features/proofread.svg" loading="lazy" decoding="async" alt="proofread">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Technical Checks</h3>
                            <p>Ready to submit your paper? Trinka evaluates your manuscript against 20+ checkpoints that match
                                journal editorial checks so you can confidently submit it to your desired journal.
                            </p>
                            <a [routerLink]="['/features/technical-checks']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/features/s3.svg" loading="lazy" decoding="async" alt="publication">
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Online<br/> Paraphraser</h3>
                            <p>AI-powered paraphraser that understands your intent and offers suggestions to make your writing clear, coherent, and grammatically correct.
                            </p>
                            <a [routerLink]="['/features/paraphraser']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image">
                            <img src="../../../assets/images/features/paraphraser.jpg" loading="lazy" decoding="async" alt="publication">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3>Citation Checker</h3>
                            <p>Citation Checker identifies citations that can make your research premise and argumentation weaker and helps you cite credible, strong citations for your paper.
                            </p>
                            <a [routerLink]="['/features/citation-formatter']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/features/s2.svg" loading="lazy" decoding="async" alt="citation">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Citation Formatter</h3>
                            <p>Format, validate, and standardise citations for all major styles at one go to save time and improve your manuscript quality.
                            </p>
                            <a [routerLink]="['/features/citation-formatter']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/features/Citation_Formatter_feature.svg" loading="lazy" decoding="async" alt="citation formatter">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3>Journal Finder</h3>
                            <p>Identifies the right journal by comparing the concepts in your paper against millions of publications and publication trends.
                            </p>
                            <a  [routerLink]="['/features/journal-finder']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/features/s1.svg" loading="lazy" decoding="async" alt="journal-finder">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Consistency Check</h3>
                            <p>Consistent writing strengthens your communication. Trinka's Consistency Checks help you eliminate
                                inconsistencies in your writing with one click. No more inconsistent hyphens and spellings!</p>
                            <a [routerLink]="['/features/consistency-check']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image">
                            <img src="../../../assets/images/features/Consistency-Check.svg" loading="lazy" decoding="async" alt="consistency">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3>Personal Dictionary</h3>
                            <p>Personalize your writing experience with your own dictionary. Add as many words to your dictionary
                                and Trinka will remember them all.</p>
                            <a [routerLink]="['/features/personal-dictionary']" class="link-text">
                                Read More
                                <img src="../../../assets/images/ReadMoreArrow-blue.png" alt="icon">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image">
                            <img src="../../../assets/images/features/Personal-Dictionary.png" loading="lazy" decoding="async" alt="personal-dictionary" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Download Your Document with Track Changes</h3>
                            <p>Want to review all your changes? That's simple. Just download the track-changed file and review
                                it within MS Word whenever you like.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image">
                            <img src="../../../assets/images/DownloadFileTrackChanges.svg" loading="lazy" decoding="async" alt="document changes" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">What are you waiting for?</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Register for Free</a>
                        <a [routerLink]="['/grammar-checker']" class="white-btn">Try a Demo</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</div>