import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { LinkService } from '../../_services/langhfre.service';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-essay-checker',
  templateUrl: './essay-checker.component.html',
  styleUrls: ['./essay-checker.component.scss']
})
export class EssayCheckerComponent implements OnInit, AfterViewInit  {

  constructor(private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Free Essay Checker | Trinka AI');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka's essay checker finds and corrects language and grammar errors in seconds. Students can write better essays quickly and confidently with Trinka AI." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Free Essay Checker | Trinka AI' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Trinka's essay checker finds and corrects language and grammar errors in seconds. Students can write better essays quickly and confidently with Trinka AI." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/essay-checker' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/free-essay-checker.png' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Essay Checker | Trinka AI' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka's essay checker finds and corrects language and grammar errors in seconds. Students can write better essays quickly and confidently with Trinka AI." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/free-essay-checker.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/essay-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/essay-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/essay-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/essay-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/essay-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/essay-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/essay-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/essay-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/essay-checker' });

    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Essay Checker","item":"https://www.trinka.ai/essay-checker"}]}
    `;
    this._renderer2.appendChild(this._document.head, script);
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
    }
  }

  

}
