import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
import * as $ from 'jquery';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
//declare function ire(string,{}): any;
@Component({
  selector: 'app-word-plugin',
  templateUrl: './word-plugin.component.html',
  styleUrls: ['./word-plugin.component.scss']
})
export class wordpluginComponent implements OnInit {
  deviceInfo = null;
  constructor(private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private deviceDetectorService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    // console.log(this.deviceInfo);
    this.titleService.setTitle("Free Grammar Checker Add-on for MS Word - Trinka's MS Word  Plug-in");
    this.metaTagService.updateTag({ name: 'description', content: "Use Trinka's MS Word add-on as a plug-in to get professional grammar corrections and suggestions as you write within your familiar Word app." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Free Grammar Checker Add-on for MS Word - Trinka's MS Word  Plug-in" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Use Trinka's MS Word add-on as a plug-in to get professional grammar corrections and suggestions as you write within your familiar Word app." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/wordplugin' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/word-add-in-banner.svg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: "Free Grammar Checker Add-on for MS Word - Trinka's MS Word  Plug-in" });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Use Trinka's MS Word add-on as a plug-in to get professional grammar corrections and suggestions as you write within your familiar Word app." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/word-add-in-banner.svg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/wordplugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/wordplugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/wordplugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/wordplugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/wordplugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/wordplugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/wordplugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/wordplugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/wordplugin' });
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      $.getScript('../../../assets/js/about_us_testimonial_silders.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      if (navigator.userAgent.indexOf("WOW64") != -1 ||
        navigator.userAgent.indexOf("Win64") != -1) {
        document.getElementById("windowInfo64bit").innerHTML = '<a  style="color:#FFF" href="https://s3.amazonaws.com/trinka-word-plugin/64bit/Trinka.Installer.msi" target="_blank"><img src="../../../assets/images/whitePaper_download.png" alt="whitepaper" style="width: 26px; margin: -6.5px 10px 0 0;"> Download Now</a>';
        document.getElementById("windowInfo64bit1").innerHTML = '<a href="https://s3.amazonaws.com/trinka-word-plugin/64bit/Trinka.Installer.msi" target="_blank" class="text-link">Download Now</a>';
      } else {
        document.getElementById("windowInfo64bit").innerHTML = '<a style="color:#FFF" href="https://s3.amazonaws.com/trinka-word-plugin/32bit/Trinka.Installer.msi" target="_blank"><img src="../../../assets/images/whitePaper_download.png" alt="whitepaper" style="width: 26px; margin: -6.5px 10px 0 0;"> Download Now</a>';
        document.getElementById("windowInfo64bit1").innerHTML = '<a href="https://s3.amazonaws.com/trinka-word-plugin/32bit/Trinka.Installer.msi" target="_blank" class="text-link">Download Now</a>';
      }
      var OSName = "Unknown OS";
      if (navigator.userAgent.indexOf("Win") != -1) {
        OSName = "Windows";
        $("#MacMSWordButton").css("display", "none");
      } else if (navigator.userAgent.indexOf("Mac") != -1) {
        OSName = "MacOS";
        $("#MSWindowDownloadButton").css("display", "none");
        $("#MSWindowTextContent").css("display", "none");
        $("#MacMSWordButton").css("display", "block");
      } else if (navigator.userAgent.indexOf("X11") != -1) {
        OSName = "UNIX";
        $("#MacMSWordButton").css("display", "none");
      } else if (navigator.userAgent.indexOf("Linux") != -1) {
        OSName = "Linux";
        $("#MacMSWordButton").css("display", "none");
      }
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"http://schema.org","@type":"VideoObject","name":"Trinka Grammar Checker for MS Word: Product Walkthrough for Installing the Add-In","description":"Welcome to our product walkthrough series for the Trinka AI grammar checker tool! In this video, we'll be showing you how to easily download and install the Trinka add-in for Microsoft Word.   Our step-by-step instructions will guide you through the process, making it quick and easy to get the most out of our powerful grammar checking tool.   Whether you're a student, a professional, or a writer, Trinka will help you take your writing to the next level. So don't wait, watch now and start improving your grammar today!  Check Now : https://www.trinka.ai/wordplugin  Join us on our Social Channels  Twitter - https://twitter.com/thetrinkaai  Instagram - https://www.instagram.com/thetrinkaai/  Facebook - https://www.facebook.com/thetrinkaai/  LinkedIn - https://www.linkedin.com/company/thetrinkaai","thumbnailUrl":"https://i.ytimg.com/vi/cTo16u94rxc/default.jpg","uploadDate":"2021-10-28T8:30:00+00:00","duration":"PT2M12S","embedUrl":"https://www.youtube.com/embed/cTo16u94rxc","interactionCount":"1701"}
    `;
    this._renderer2.appendChild(this._document.head, script);
    
    //ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    // if (isPlatformBrowser(this.platformId)) {
    //   // $.getScript('../../../assets/js/particles.min.js', function(){});
    //   //$.getScript('../../../assets/js/wisepop.js', function () { });
    //   $.getScript('../../../assets/js/about_us_testimonial_silders.js', function () { });
    //   if (navigator.userAgent.indexOf("WOW64") != -1 ||
    //     navigator.userAgent.indexOf("Win64") != -1) {
    //     document.getElementById("windowInfo64bit").innerHTML = '<a  style="color:#FFF" href="https://s3.amazonaws.com/trinka-word-plugin/64bit/Trinka.Installer.msi" target="_blank"><img src="../../../assets/images/whitePaper_download.png" alt="" style="width: 26px; margin: -6.5px 10px 0 0;"> Download Now</a>';
    //   } else {
    //     document.getElementById("windowInfo64bit").innerHTML = '<a style="color:#FFF" href="https://s3.amazonaws.com/trinka-word-plugin/32bit/Trinka.Installer.msi" target="_blank"><img src="../../../assets/images/whitePaper_download.png" alt="" style="width: 26px; margin: -6.5px 10px 0 0;"> Download Now</a>';
    //   }

    // }

  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  
}
