import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
// import {Location} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './Shared/header/header.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { HomeComponent } from './Components/home/home.component';
import { ExamplesComponent } from './Components/examples/examples.component';
import { PricingComponent } from './Components/pricing/pricing.component';
import { ContactUsComponent } from './Components/contact-us/contact-us.component';
import { WorkWithUsComponent } from './Components/work-with-us/work-with-us.component';
import { PrivacyPolicyComponent } from './Components/privacy-policy/privacy-policy.component';
import { TermServicesComponent } from './Components/term-services/term-services.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { GcEditorComponent } from './Components/gc-editor/gc-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './models/material-components.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CKEditorModule } from 'ngx-ckeditor';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModuleList } from './material-module';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { AppComponent } from './app.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ReferralComponent } from './Components/referral/referral.component';
//import { ProfessionalEditingServiceComponent } from './Components/professional-editing-service/professional-editing-service.component';
// import { WebinarOneComponent } from './Components/webinar/webinar-one/webinar-one.component';
// import { PowerModeEditingComponent } from './Components/power-mode-editing/power-mode-editing.component';
import { AcademicPhrasebankComponent } from './Components/academic-phrasebank/academic-phrasebank.component';
import { AcademicPhrasebankBrowseComponent } from './Components/academic-phrasebank-browse/academic-phrasebank-browse.component';
import { HighlightKeywordsDirective } from "./_directives/highlightKeywords.directive";
import { PaginationComponent } from "./Components/pagination/pagination.component";
import { NgGoogleOneTapModule } from 'ng-google-one-tap';
import { TokenInterceptorService } from '../app/_services/token-interceptor.service'
import { PunctuationCheckerComponent } from './Components/punctuation-checker/punctuation-checker.component';
import { SpellCheckerComponent } from './Components/spell-checker/spell-checker.component';
import { SentenceCheckerComponent } from './Components/sentence-checker/sentence-checker.component';
import { GrammarCheckerTestComponent } from './Components/grammar-checker-test/grammar-checker-test.component';
import { CookieService } from 'ngx-cookie-service';
import { ParapharsingToolComponent } from './Components/parapharsing-tool/parapharsing-tool.component';
import { FeedbackModalComponent } from "./Components/feedback-modal/feedback-modal.component";
import { AiDetectorToolComponent } from './Components/ai-detector-tool/ai-detector-tool.component';
import { DetectionLimitExhaustModalComponent } from './Components/detection-limit-exhaust-modal/detection-limit-exhaust-modal.component';
import { AiContentFeedbackModalComponent } from './Components/ai-content-feedback-modal/ai-content-feedback-modal.component';
import { WisePopupComponent } from './Components/wise-popup/wise-popup.component';
import { MultilingualEditorMarketingModule } from '@crimson-mohit/multilingual-editor-marketing';
//import { DeGrammarCheckerComponent } from './Components/de/de-grammar-checker/de-grammar-checker.component';
//import { DeDetectionLimitExhaustModalComponent } from './Components/de/de-detection-limit-exhaust-modal/de-detection-limit-exhaust-modal.component';
//import { DeFeedbackModalComponent } from './Components/de/de-feedback-modal/de-feedback-modal.component';
import { EnglishGrammarCheckerComponent } from './Components/english-grammar-checker/english-grammar-checker.component';
import { SpanishGrammarCheckerComponent } from './Components/spanish-grammar-checker/spanish-grammar-checker.component';
//import { GermanGrammarCheckerComponent } from './Components/german-grammar-checker/german-grammar-checker.component';
import { SharedModule } from './modules/shared.module';
import { FeaturesComponent } from './Components/features/features.component';
import { ConsistencyChecksComponent } from './Components/features/consistency-checks/consistency-checks.component';
import { PersonalDictionaryComponent } from './Components/features/personal-dictionary/personal-dictionary.component';
import { PublicationChecksComponent } from './Components/publication-checks/publication-checks.component';
import { AutoEditComponent } from './Components/auto-edit/auto-edit.component';
import { PlagiarismCheckerComponent } from './Components/features/plagiarism-checker/plagiarism-checker.component';
import { OnlineParaphraserComponent } from './Components/features/online-paraphraser/online-paraphraser.component';
import { CreditsComponent } from './Components/features/credits/credits.component';
import { CitationCheckerComponent } from './Components/features/citation-checker/citation-checker.component';
import { JournalFinderComponent } from './Components/features/journal-finder/journal-finder.component';
import { LatexGrammarCheckerComponent } from './Components/features/latex-grammar-checker/latex-grammar-checker.component';
import { GrammarCheckerFeaturesComponent } from './Components/features/grammar-checker-features/grammar-checker-features.component';
import { InclusiveLanguageReportComponent } from './Components/features/inclusive-language-report/inclusive-language-report.component';
import { LegalWritingComponent } from './Components/features/legal-writing/legal-writing.component';
import { EnterpriseComponent } from './Components/enterprise/enterprise.component';
import { LifeSciencesMedicineAndPharmaComponent } from './Components/life-sciences-medicine-and-pharma/life-sciences-medicine-and-pharma.component';
import { LanguageServicesPublishingAndMediaComponent } from './Components/language-services-publishing-and-media/language-services-publishing-and-media.component';
import { AcademicInstitutionsComponent } from './Components/academic-institutions/academic-institutions.component';
import { K12AndElearningComponent } from './Components/k12-and-elearning/k12-and-elearning.component';
import { TechnologyPlatformsComponent } from './Components/technology-platforms/technology-platforms.component';
import { APIComponent } from './Components/enterprise/api/api.component';
import { DataSensitivePlanComponent } from './Components/enterprise/data-sensitive-plan/data-sensitive-plan.component';
import { wordpluginComponent } from './Components/word-plugin/word-plugin.component';
import { TrinkaCloudComponent } from './Components/trinka-cloud/trinka-cloud.component';
import { BrowserPluginComponent } from './Components/browser-plugin/browser-plugin.component';
import { MacwordpluginComponent } from './Components/macwordplugin/macwordplugin.component';
import { FreeGrammarCheckerComponent } from './Components/free-grammar-checker/free-grammar-checker.component';
import { APILandingPageComponent } from './Components/apilanding-page/apilanding-page.component';
import { ThesisCheckerComponent } from './Components/resource/thesis-checker/thesis-checker.component';
import { EssayCheckerComponent } from './Components/essay-checker/essay-checker.component';
import { ProofreadingToolComponent } from './Components/proofreading-tool/proofreading-tool.component';
import { OfficialInvoiceComponent } from './Components/official-invoice/official-invoice.component';
import { AffiliatesComponent } from './Components/affiliates/affiliates.component';
import { BrandComponent } from './Components/brand/brand.component';
import { TryADemoComponent } from './Components/try-ademo/try-ademo.component';
import { CampusAmbassadorComponent } from './Components/campus-ambassador/campus-ambassador.component';
import { SafeSecureComponent } from './Components/safe-secure/safe-secure.component';
import { JournalFinderToolComponent } from './Components/journal-finder-tool/journal-finder-tool.component';
import { OpenAccessFilterPipe } from './_pipes/open-access.pipe';
import { ChartComponent } from './chart/chart.component';
import { TargetJournalFilterPipe } from './_pipes/target-journal.pipe';
import { PredatoryFilterPipe } from './_pipes/predatory.pipe';
import { CiteScorePipe } from './_pipes/cite-score.pipe';
import { HIndexPipe } from './_pipes/h-index.pipe';
import { ImpactFactorPipe } from './_pipes/impact-factor.pipe';
import { ChartsModule } from 'ng2-charts';
import { JournalFinderToolsPageComponent } from './Components/journal-finder-tools-page/journal-finder-tools-page.component';
import { PowerModeSectionComponent } from './Components/features/power-mode-section/power-mode-section.component';
import { WindowsDesktopAppComponent } from './Components/windows-desktop-app/windows-desktop-app.component';
import { PricingV2Component } from './Components/pricing-v2/pricing-v2.component';
import { WindowsAppFeedbackComponent } from './Components/windows-desktop-app/windows-app-feedback/windows-app-feedback.component';
import { CustomAIModelsComponent } from './Components/enterprise/custom-aimodels/custom-aimodels.component';
import { HowWeDoItComponent } from './Components/enterprise/custom-aimodels/how-we-do-it/how-we-do-it.component';
import { ProcessModelComponent } from './Components/enterprise/custom-aimodels/process-model/process-model.component';
import { WhyChooseCustomAIComponent } from './Components/enterprise/custom-aimodels/why-choose-custom-ai/why-choose-custom-ai.component';
import { RLHFModelComponent } from './Components/enterprise/custom-aimodels/rlhfmodel/rlhfmodel.component';
import { ElsevierJournalComponent } from './Components/journal-finder-tools-page/elsevier-journal/elsevier-journal.component';
import { ElsevierJournalToolComponent } from './Components/journal-finder-tool/elsevier-journal-tool/elsevier-journal-tool.component';
import { SpringerJournalComponent } from './Components/journal-finder-tools-page/springer-journal/springer-journal.component';
import { SpringerJournalToolComponent } from './Components/journal-finder-tool/springer-journal-tool/springer-journal-tool.component';
import { WileyJournalComponent } from './Components/journal-finder-tools-page/wiley-journal/wiley-journal.component';
import { WileyJournalToolComponent } from './Components/journal-finder-tool/wiley-journal-tool/wiley-journal-tool.component';
import { TaylorAndFrancisJournalComponent } from './Components/journal-finder-tools-page/taylor-and-francis-journal/taylor-and-francis-journal.component';
import { TaylorAndFrancisJournalToolComponent } from './Components/journal-finder-tool/taylor-and-francis-journal-tool/taylor-and-francis-journal-tool.component';
import { SageJournalComponent } from './Components/journal-finder-tools-page/sage-journal/sage-journal.component';
import { SageJournalToolComponent } from './Components/journal-finder-tool/sage-journal-tool/sage-journal-tool.component';
import { LearnMoreSectionComponent } from './Components/enterprise/custom-aimodels/learn-more-section/learn-more-section.component';
import { AIContentDetectorFeaturesComponent } from './Components/features/aicontent-detector-features/aicontent-detector-features.component';
import { WhatMakeUsStandOutComponent } from './Components/features/features-shared/what-make-us-stand-out/what-make-us-stand-out.component';
import { SecuritySectionComponent } from './Components/security-section/security-section.component';
import { CitationFormatterComponent } from './Components/features/citation-formatter/citation-formatter.component';
import { EnterpriseModalModule } from './modules/enterprise-modal/enterprise-modal.module';
import { AIAppsLibraryComponent } from './Components/features/ai-studio/aiapps-library/aiapps-library.component';
import { AppsLibraryComponent } from './Components/features/ai-studio/apps-library/apps-library.component';
import { AIAppsPlansComponent } from './Components/features/ai-studio/aiapps-plans/aiapps-plans.component';
import { SuggestionAlertsComponent } from './Components/features/features-shared/suggestion-alerts/suggestion-alerts.component';
import { AskTrinkaComponent } from './Components/features/ai-studio/ask-trinka/ask-trinka.component';
import { GrammarWithoutInternetComponent } from './Components/enterprise/grammar-without-internet/grammar-without-internet.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ExamplesComponent,
    PricingComponent,
    ContactUsComponent,
    WorkWithUsComponent,
    PrivacyPolicyComponent,
    TermServicesComponent,
    PageNotFoundComponent,
    GcEditorComponent,
    ReferralComponent,
    //ProfessionalEditingServiceComponent,
    //WebinarOneComponent,
    //PowerModeEditingComponent,
    AcademicPhrasebankComponent,
    AcademicPhrasebankBrowseComponent,
    HighlightKeywordsDirective,
    PaginationComponent,
    PunctuationCheckerComponent,
    SpellCheckerComponent,
    SentenceCheckerComponent,
    GrammarCheckerTestComponent,
    ParapharsingToolComponent,
    FeedbackModalComponent,
    //DeFeedbackModalComponent,
    AiDetectorToolComponent,
    DetectionLimitExhaustModalComponent,
    //DeDetectionLimitExhaustModalComponent,
    AiContentFeedbackModalComponent,
    FeedbackModalComponent,
    WisePopupComponent,
    //DeGrammarCheckerComponent,
    EnglishGrammarCheckerComponent,
    SpanishGrammarCheckerComponent,
    //GermanGrammarCheckerComponent,
    FeaturesComponent,
    ConsistencyChecksComponent,
    PersonalDictionaryComponent,
    PublicationChecksComponent,
    AutoEditComponent,
    PlagiarismCheckerComponent,
    OnlineParaphraserComponent,
    CreditsComponent,
    CitationCheckerComponent,
    JournalFinderComponent,
    LatexGrammarCheckerComponent,
    GrammarCheckerFeaturesComponent,
    InclusiveLanguageReportComponent,
    LegalWritingComponent,
    EnterpriseComponent,
    LifeSciencesMedicineAndPharmaComponent,
    LanguageServicesPublishingAndMediaComponent,
    AcademicInstitutionsComponent,
    K12AndElearningComponent,
    TechnologyPlatformsComponent,
    APIComponent,
    DataSensitivePlanComponent,
    wordpluginComponent,
    TrinkaCloudComponent,
    BrowserPluginComponent,
    MacwordpluginComponent,
    FreeGrammarCheckerComponent,
    APILandingPageComponent,
    ThesisCheckerComponent,
    EssayCheckerComponent,
    ProofreadingToolComponent,
    OfficialInvoiceComponent,
    AffiliatesComponent,
    BrandComponent,
    TryADemoComponent,
    CampusAmbassadorComponent,
    SafeSecureComponent,
    JournalFinderToolComponent,
    OpenAccessFilterPipe,
    TargetJournalFilterPipe,
    PredatoryFilterPipe,
    CiteScorePipe,
    HIndexPipe,
    ImpactFactorPipe,
    ChartComponent,
    JournalFinderToolsPageComponent,
    PowerModeSectionComponent,
    WindowsDesktopAppComponent,
    PricingV2Component,
    WindowsAppFeedbackComponent,
    CustomAIModelsComponent,
    HowWeDoItComponent,
    ProcessModelComponent,
    WhyChooseCustomAIComponent,
    RLHFModelComponent,
    ElsevierJournalComponent,
    ElsevierJournalToolComponent,
    SpringerJournalComponent,
    SpringerJournalToolComponent,
    WileyJournalComponent,
    WileyJournalToolComponent,
    TaylorAndFrancisJournalComponent,
    TaylorAndFrancisJournalToolComponent,
    SageJournalComponent,
    SageJournalToolComponent,
    LearnMoreSectionComponent,
    AIContentDetectorFeaturesComponent,
    WhatMakeUsStandOutComponent,
    SecuritySectionComponent,
    CitationFormatterComponent,
    AIAppsLibraryComponent,
    AppsLibraryComponent,
    AIAppsPlansComponent,
    SuggestionAlertsComponent,
    AskTrinkaComponent,
    SuggestionAlertsComponent,
    GrammarWithoutInternetComponent
  ],
  imports: [
    MultilingualEditorMarketingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CKEditorModule,
    MaterialModule,
    MaterialModuleList,
    AppRoutingModule,
    HttpClientModule,
    NgtUniversalModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    TransferHttpCacheModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    EnterpriseModalModule,
    SharedModule,
    ChartsModule,
    NgGoogleOneTapModule.config(
      // 1055332361454-ff5h774f33c5dppui1hro7ss7k39ic87.apps.googleusercontent.com
      // 205063414201-2g0mpui4rqeqfe7fpp3mo8erflhgnp1o.apps.googleusercontent.com
      {
          client_id: '355262618030-a72hounabci2iabodc52i4h5brs3crjl.apps.googleusercontent.com',
          cancel_on_tap_outside: true,
          authvalidate_by_googleapis: true,
          auto_select: false,
          disable_exponential_cooldowntime: false,
          context: 'signup'
      })
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }

// const __stripTrailingSlash = (Location as any).stripTrailingSlash;
// Location.stripTrailingSlash = function (url) {
//   if (url.endsWith('/')) {
//     url=url;
//   }
//   else {
//     url=url+'/';
//   }
//   const queryString$ = url.match(/([^?]*)?(.*)/);
//   if (queryString$[2].length > 0) {
//     return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
//   }
//   return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
// };
